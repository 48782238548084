import React, { useState, useEffect } from "react";
import { Button } from "./components/ui/button";
import { Menu, X, ChevronDown, ArrowRight } from "lucide-react";
import { ThemeProvider } from "./components/theme-provider";
import { ThemeSwitcher } from "./components/theme-switcher";
import { ProjectCarousel } from "./components/project-carousel";
import { ServicesSection } from "./components/services-section";
import { ProcessAndSolutions } from "./components/process-solutions";
import { DesignFeatures } from "./components/design-features";
import { Footer } from "./components/footer";
import { Logo } from "./components/logo";
import { DynamicText } from "./components/dynamic-text";

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ThemeProvider defaultTheme="dark" storageKey="app-theme">
      <div className="min-h-screen flex flex-col bg-background text-foreground overflow-x-hidden">
        {/* Navigation */}
        <nav
          className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
            isScrolled
              ? "bg-background/80 backdrop-blur-md border-b border-border shadow-lg"
              : "bg-transparent"
          }`}
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-20 items-center">
              <Logo className="transition-transform hover:scale-105" />

              <div className="hidden md:flex md:space-x-1">
                {[
                  { name: "Home", id: "home" },
                  { name: "Projects", id: "projects" },
                  { name: "Services", id: "services" },
                  { name: "Process", id: "process" },
                  { name: "Why Us?", id: "why-us" },
                ].map((item) => (
                  <Button
                    key={item.name}
                    variant="ghost"
                    className="text-foreground/60 hover:text-foreground relative group px-4 py-2"
                    onClick={() => scrollToSection(item.id)}
                  >
                    <span className="relative z-10">{item.name}</span>
                    <span className="absolute inset-0 bg-foreground/5 rounded-lg scale-0 group-hover:scale-100 transition-transform duration-200" />
                  </Button>
                ))}
              </div>

              <div className="flex items-center gap-6">
                <ThemeSwitcher />
                <Button
                  variant="outline"
                  size="sm"
                  className="hidden md:flex border-[#6366F1] text-[#6366F1] hover:bg-[#6366F1] hover:text-white"
                  onClick={() => window.location.href = 'mailto:hi@binaasetdigital.com?subject=Get Started Inquiry'}
                >
                  Get Started
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="md:hidden"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  {isMenuOpen ? (
                    <X className="h-6 w-6" />
                  ) : (
                    <Menu className="h-6 w-6" />
                  )}
                </Button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <div
            className={`md:hidden transition-all duration-300 ease-in-out ${
              isMenuOpen
                ? "max-h-screen opacity-100"
                : "max-h-0 opacity-0 pointer-events-none"
            }`}
          >
            <div className="px-4 pt-2 pb-8 space-y-2 bg-background/80 backdrop-blur-md border-t border-border">
              {[
                { name: "Home", id: "home" },
                { name: "Services", id: "services" },
                { name: "Projects", id: "projects" },
                { name: "Process", id: "process" },
                { name: "Why Us?", id: "why-us" },
              ].map((item) => (
                <Button
                  key={item.name}
                  variant="ghost"
                  className="w-full justify-start text-foreground/60 hover:text-foreground"
                  onClick={() => scrollToSection(item.id)}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="flex-1">
          {/* Hero Section */}
          <section
            id="home"
            className="relative min-h-screen flex items-center"
          >
            {/* Animated background gradients */}
            <div className="absolute inset-0 bg-gradient-to-br from-[#8B5CF6] via-[#E879F9] to-[#F0ABFC] opacity-20" />
            <div className="absolute inset-0">
              <div className="absolute -left-20 -top-20 w-96 h-96 rounded-full bg-[#8B5CF6] opacity-20 blur-3xl animate-pulse" />
              <div className="absolute -right-20 -bottom-20 w-96 h-96 rounded-full bg-[#EC4899] opacity-20 blur-3xl animate-pulse delay-1000" />
              <div className="absolute left-1/3 top-1/4 w-72 h-72 rounded-full bg-[#F59E0B] opacity-20 blur-3xl animate-pulse delay-500" />
            </div>

            {/* Content */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-32 text-center relative z-10">
              <h1 className="text-4xl md:text-5xl lg:text-7xl font-bold tracking-tight mb-8">
                <span className="block mb-2">We are a digital web</span>
                <span className="block mb-4">agency specializing on</span>
              </h1>
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold tracking-tight mb-8">
                <DynamicText />
              </h2>
              <p className="text-lg md:text-xl text-foreground/70 max-w-2xl mx-auto mb-12">
                We create cutting-edge web applications, custom systems, and
                integrated digital solutions tailored to your business needs.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Button className="group bg-[#6366F1] hover:bg-[#4F46E5] text-white px-8 py-6 text-lg rounded-full transition-all duration-300 hover:scale-105">
                  See Project
                  <ArrowRight className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1" />
                </Button>
                <Button className="group bg-[#EC4899] hover:bg-[#DB2777] text-white text-lg px-8 py-6 rounded-full transition-all duration-300 hover:scale-105">
                  Contact Us
                  <ArrowRight className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1" />
                </Button>
              </div>
            </div>
          </section>

          <ProjectCarousel id="projects" />
          <ServicesSection id="services" />
          <ProcessAndSolutions id="process" />
          <DesignFeatures id="why-us" />
          <Footer />
        </main>
      </div>
    </ThemeProvider>
  );
};

export default App;
