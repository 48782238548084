import React from "react";
import { ArrowUpRight } from "lucide-react";
import { Button } from "./ui/button";

interface ProcessStep {
  number: string;
  title: string;
  description: string;
  bgColor: string;
}

interface ProcessAndSolutionsProps {
  id?: string;
}

const processSteps: ProcessStep[] = [
  {
    number: "01",
    title: "Collect Ideas",
    description:
      "We begin by gathering insights and brainstorming creative solutions. Our team collaborates closely with you to understand your vision, goals, and challenges. This ensures a solid foundation for your business process model.",
    bgColor: "bg-[#6366F1]",
  },
  {
    number: "02",
    title: "Data Analysis",
    description:
      "Next, we analyze relevant data to identify key trends and insights. This step helps us make informed decisions and design tailored solutions that align with your business objectives.",
    bgColor: "bg-[#EC4899]",
  },
  {
    number: "03",
    title: "Finalize Product",
    description:
      "With a clear strategy in place, we refine and implement the business model. Our team ensures every detail is optimized for performance, delivering a comprehensive and effective solution.",
    bgColor: "bg-[#10B981]",
  },
];

export function ProcessAndSolutions({ id }: ProcessAndSolutionsProps) {
  return (
    <section id={id} className="w-full py-24 bg-[#1C1D24] text-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-20">
          {/* Working Process */}
          <div className="space-y-12">
            <div>
              <h2 className="text-4xl font-bold text-[#8B5CF6] mb-4">
                Our Working Process
              </h2>
              <p className="text-xl text-gray-400">
                Discover how we transform ideas into actionable business models
                with a streamlined, results-driven approach.
              </p>
            </div>

            <div className="space-y-6">
              {processSteps.map((step) => (
                <div key={step.number} className="rounded-2xl bg-[#25262B] p-6">
                  <div className="flex gap-6">
                    <div
                      className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-xl ${step.bgColor} text-white font-bold`}
                    >
                      {step.number}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-3">
                        {step.title}
                      </h3>
                      <p className="text-gray-400 leading-relaxed">
                        {step.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Our Solutions */}
          <div className="lg:pt-0">
            <div className="space-y-12">
              <div>
                <h2 className="text-4xl font-bold text-[#EC4899] mb-4">
                  Our Solutions
                </h2>
                <p className="text-xl text-gray-400">
                  We provide innovative and scalable solutions tailored to
                  support your business growth at every stage.
                </p>
              </div>

              <div className="space-y-8">
                <p className="text-gray-400 leading-relaxed">
                  From concept to execution, we combine strategic thinking with
                  cutting-edge technology to build sustainable business models.
                  Our approach integrates market insights, operational
                  efficiency, and customer-centric design for maximum impact and
                  long-term success.
                </p>

                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold bg-gradient-to-r from-[#8B5CF6] to-[#EC4899] bg-clip-text text-transparent">
                    Let's create something exceptional together.
                  </h3>
                  <Button 
                    className="bg-gradient-to-r from-[#8B5CF6] to-[#EC4899] text-white px-8 py-6 text-lg rounded-full group hover:opacity-90"
                    onClick={() => window.location.href = 'mailto:hi@binaasetdigital.com?subject=Get in Touch Inquiry'}
                  >
                    Get in Touch Today!
                    <ArrowUpRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
