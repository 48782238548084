import React, { useState, useEffect } from "react";

const texts = [
  {
    text: "custom system",
    gradient: "from-purple-500 via-pink-500 to-orange-500",
  },
  {
    text: "custom app",
    gradient: "from-blue-500 via-teal-500 to-emerald-500",
  },
  {
    text: "custom API",
    gradient: "from-rose-500 via-fuchsia-500 to-indigo-500",
  },
  {
    text: "web app",
    gradient: "from-amber-500 via-orange-500 to-red-500",
  },
  {
    text: "super cool website",
    gradient: "from-green-500 via-emerald-500 to-teal-500",
  },
];

export const DynamicText = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsVisible(true);
      }, 200); // Faster fade out transition
    }, 1000); // Change every 1 second

    return () => clearInterval(intervalId);
  }, []);

  return (
    <span
      className={`bg-gradient-to-r ${
        texts[currentIndex].gradient
      } bg-clip-text text-transparent transition-opacity duration-500 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      {texts[currentIndex].text}
    </span>
  );
};
