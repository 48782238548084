import React from "react";
import { Logo } from "./logo";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#1C1D24] text-white py-20 w-full relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-grid-white/5 [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]" />
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-blue-500 opacity-10 blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500 opacity-10 blur-3xl" />
      </div>

      {/* Curved top border */}
      <div
        className="absolute top-0 left-0 right-0 h-20 bg-background"
        style={{
          clipPath: "ellipse(70% 100% at 50% 0%)",
        }}
      />

      <div className="container mx-auto px-4 max-w-6xl relative">
        <div className="grid grid-cols-1 gap-x-8 gap-y-12 pt-32">
          {/* Brand Section */}
          <div className="flex flex-col items-center">
            <Logo className="h-8 text-white mb-6 hover:scale-105 transition-transform" />
            <div className="text-gray-400 space-y-1 text-center">
              <p>{currentYear} Bina Aset Digital</p>
              <p className="hover:text-white transition-colors cursor-default">
                Melaka Malaysia
              </p>
              <p className="mt-4 hover:text-white transition-colors">
                <a href="mailto:hi@binaasetdigital.com">Contact</a>
              </p>
              <p>All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
