import React from "react";
import {
  Paintbrush,
  Palette,
  Layers,
  Smartphone,
  CloudCog,
  Search,
  Code,
  Laptop,
  Sparkles,
  HeartHandshake,
} from "lucide-react";

const features = [
  {
    title: "Expertise in Modern Technologies",
    description:
      "We excel in cutting-edge technologies like Laravel, Vue.js, React, and more.",
    icon: Code,
    gradient: "from-blue-500 to-cyan-500",
  },
  {
    title: "Customized Solutions",
    description:
      "Every project is designed to meet your specific requirements and business goals.",
    icon: Laptop,
    gradient: "from-purple-500 to-pink-500",
  },
  {
    title: "Results-Driven Approach",
    description:
      "We focus on delivering measurable success through innovation and precision.",
    icon: Sparkles,
    gradient: "from-amber-500 to-orange-500",
  },
  {
    title: "Reliable Support",
    description:
      "Dedicated customer service and ongoing project maintenance to ensure your success.",
    icon: HeartHandshake,
    gradient: "from-green-500 to-emerald-500",
  },
];

interface DesignFeaturesProps {
  id?: string;
}

export const DesignFeatures: React.FC<DesignFeaturesProps> = ({ id }) => {
  return (
    <section id={id} className="w-full py-32 bg-gradient-to-b from-background via-background/95 to-background relative overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-grid-white/5 [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]" />
        <div className="absolute top-0 -left-4 w-96 h-96 bg-purple-500 opacity-10 blur-3xl animate-pulse" />
        <div className="absolute bottom-0 -right-4 w-96 h-96 bg-blue-500 opacity-10 blur-3xl animate-pulse delay-700" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Section Header */}
        <div className="text-center mb-24 relative">
          <div className="inline-block">
            <h2 className="text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
              Why Choose Us?
            </h2>
            <div className="absolute -bottom-4 left-0 right-0 h-1 bg-gradient-to-r from-blue-500/0 via-purple-500/50 to-pink-500/0 blur-sm" />
          </div>
          <p className="text-xl text-muted-foreground max-w-3xl mx-auto leading-relaxed">
            Let us transform your ideas into reality. Experience unparalleled
            web development services that drive your business forward.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="group relative">
              {/* Card container with hover effects */}
              <div className="relative p-8 rounded-3xl bg-card/50 backdrop-blur-sm border hover:bg-accent/5 transition-all duration-500 hover:shadow-2xl hover:-translate-y-2">
                {/* Gradient background effect */}
                <div
                  className={`absolute -inset-px rounded-3xl bg-gradient-to-r ${feature.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500 blur-xl`}
                />

                {/* Icon container */}
                <div className="mb-8">
                  <div
                    className={`w-16 h-16 rounded-2xl bg-gradient-to-r ${feature.gradient} p-0.5`}
                  >
                    <div className="w-full h-full rounded-2xl bg-background flex items-center justify-center group-hover:scale-110 transition-transform duration-500">
                      <feature.icon className="w-8 h-8 text-primary group-hover:animate-pulse" />
                    </div>
                  </div>
                </div>

                {/* Content */}
                <h3 className="text-2xl font-bold mb-4 group-hover:text-primary transition-colors duration-300">
                  {feature.title}
                </h3>
                <p className="text-muted-foreground leading-relaxed group-hover:text-foreground transition-colors duration-300">
                  {feature.description}
                </p>

                {/* Decorative corner accent */}
                <div
                  className={`absolute top-0 right-0 w-24 h-24 bg-gradient-to-r ${feature.gradient} opacity-0 group-hover:opacity-5 transition-opacity duration-500 blur-2xl rounded-full -translate-y-12 translate-x-12`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DesignFeatures;
