import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, ExternalLink } from "lucide-react";
import { Button } from "./ui/button";

interface Project {
  id: number;
  image: string;
  category: string;
  title: string;
}

interface ProjectCarouselProps {
  id?: string;
}

const projects: Project[] = [
  {
    id: 1,
    image: "/portfolios/sini.png",
    category: "SINI",
    title: "Digital Marketplace",
  },
  {
    id: 2,
    image: "/portfolios/bacalah.png",
    category: "BACALAH",
    title: "Bacalah Al Quran",
  },
  {
    id: 3,
    image: "/portfolios/gadget.png",
    category: "GADGET",
    title: "Robust TikTok & Shopee Omnichannel",
  },
  {
    id: 4,
    image: "/portfolios/masjid.png",
    category: "TV MASJID",
    title: "Waktu Solat Masjid",
  },
  {
    id: 4,
    image: "/portfolios/cabutan.png",
    category: "Socmed Lucky Draw",
    title: "TikTok Lucky Draw for buyer",
  },
];

export const ProjectCarousel: React.FC<ProjectCarouselProps> = ({ id }) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollability = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  useEffect(() => {
    checkScrollability();
    window.addEventListener("resize", checkScrollability);
    return () => window.removeEventListener("resize", checkScrollability);
  }, []);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const scrollAmount = direction === "left" ? -400 : 400;

      container.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });

      setTimeout(() => {
        checkScrollability();
      }, 300);
    }
  };

  return (
    <section className="relative py-20" id={id}>
      <div className="absolute inset-0">
        <div className="absolute top-1/2 -translate-y-1/2 left-0 w-72 h-72 bg-purple-500/20 rounded-full blur-3xl" />
        <div className="absolute top-1/2 -translate-y-1/2 right-0 w-72 h-72 bg-blue-500/20 rounded-full blur-3xl" />
      </div>

      <div className="max-w-6xl mx-auto px-4 relative">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-blue-500">
            Featured Projects
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Explore our latest work and creative solutions
          </p>
        </div>

        <div className="relative group">
          <div
            ref={scrollContainerRef}
            className="flex overflow-x-auto snap-x snap-mandatory hide-scrollbar gap-6 pb-8"
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            onScroll={checkScrollability}
          >
            {projects.map((project) => (
              <div
                key={project.id}
                className="min-w-[300px] md:min-w-[400px] snap-start"
              >
                <div className="h-[400px] rounded-2xl overflow-hidden group/card relative bg-card border shadow-lg">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover transition-all duration-500 group-hover/card:scale-105"
                  />

                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent opacity-0 group-hover/card:opacity-100 transition-all duration-500">
                    <div className="absolute inset-0 flex flex-col justify-end p-8">
                      <div className="transform translate-y-4 group-hover/card:translate-y-0 transition-transform duration-500">
                        <div className="text-sm font-medium mb-3 text-purple-400 uppercase tracking-wider">
                          {project.category}
                        </div>
                        <h3 className="text-2xl font-bold text-white mb-4">
                          {project.title}
                        </h3>
                        <Button
                          variant="secondary"
                          size="sm"
                          className="opacity-0 group-hover/card:opacity-100 transition-opacity duration-500 delay-100"
                        >
                          View Project <ExternalLink className="w-4 h-4 ml-2" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Navigation Buttons - Simplified version without counter */}
          <div className="flex justify-center gap-4 mt-8">
            <button
              onClick={() => scroll("left")}
              disabled={!canScrollLeft}
              className={`w-10 h-10 rounded-full bg-primary/90 text-primary-foreground flex items-center justify-center transition-all duration-300 ${
                canScrollLeft
                  ? "opacity-100 hover:bg-primary hover:scale-110"
                  : "opacity-50 cursor-not-allowed"
              }`}
              aria-label="Previous project"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>

            <button
              onClick={() => scroll("right")}
              disabled={!canScrollRight}
              className={`w-10 h-10 rounded-full bg-primary/90 text-primary-foreground flex items-center justify-center transition-all duration-300 ${
                canScrollRight
                  ? "opacity-100 hover:bg-primary hover:scale-110"
                  : "opacity-50 cursor-not-allowed"
              }`}
              aria-label="Next project"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectCarousel;
