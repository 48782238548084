import React from "react";
import {
  Code2,
  PaintBucket,
  ShoppingCart,
  Settings,
  Package,
  Network,
} from "lucide-react";

interface ServicesSectionProps {
  id?: string;
}

const services = [
  {
    title: "Custom Web Development",
    description: [
      "Build scalable and secure custom web applications",
      "Develop feature-rich web portals, SaaS platforms, and CRM systems",
      "API integrations for seamless business operations",
    ],
    icon: Code2,
    gradient: "from-blue-500 to-cyan-500",
  },
  {
    title: "Web & App Design",
    description: [
      "Modern, responsive, and user-friendly web design",
      "Intuitive UI/UX tailored to your brand",
      "Cross-platform app design for Android and iOS",
    ],
    icon: PaintBucket,
    gradient: "from-purple-500 to-pink-500",
  },
  {
    title: "E-commerce Omnichannel Solutions",
    description: [
      "Multi-channel e-commerce platforms",
      "Integration with TikTok, Lazada, Shopee, and other marketplaces",
      "Custom order management, product synchronization, and payment gateways",
    ],
    icon: ShoppingCart,
    gradient: "from-green-500 to-emerald-500",
  },
  {
    title: "Custom System Development",
    description: [
      "Bespoke business solutions that streamline operations",
      "Industry-specific custom software development",
      "Automated business workflows and management systems",
    ],
    icon: Settings,
    gradient: "from-orange-500 to-yellow-500",
  },
  {
    title: "WordPress Plugins & Laravel Packages",
    description: [
      "Custom WordPress plugin development",
      "Laravel package creation for advanced functionalities",
      "Continuous support and maintenance",
    ],
    icon: Package,
    gradient: "from-red-500 to-orange-500",
  },
  {
    title: "API-Based App Development",
    description: [
      "RESTful and GraphQL API development",
      "Third-party API integrations",
      "API documentation and maintenance",
    ],
    icon: Network,
    gradient: "from-indigo-500 to-purple-500",
  },
];

export const ServicesSection: React.FC<ServicesSectionProps> = ({ id }) => {
  return (
    <section id={id} className="py-32 w-full bg-gradient-to-b from-background via-background/95 to-background relative overflow-hidden">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-grid-white/5 [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]" />
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-purple-500/30 opacity-10 blur-3xl" />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Section Header */}
        <div className="text-center mb-24">
          <h2 className="text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
            Our Core Services
          </h2>
          <p className="text-xl text-muted-foreground max-w-3xl mx-auto leading-relaxed">
            Comprehensive web development solutions tailored to your business
            needs, delivering innovation and excellence in every project.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="group relative p-8 rounded-3xl border bg-card/50 backdrop-blur-sm hover:bg-accent/5 text-card-foreground transition-all duration-500 hover:shadow-2xl hover:-translate-y-2"
            >
              {/* Gradient background effect */}
              <div
                className={`absolute -inset-px rounded-3xl bg-gradient-to-r ${service.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500 blur-xl`}
              />

              {/* Icon container */}
              <div className="mb-8 relative">
                <div
                  className={`w-16 h-16 rounded-2xl bg-gradient-to-r ${service.gradient} p-0.5`}
                >
                  <div className="w-full h-full rounded-2xl bg-background flex items-center justify-center group-hover:scale-110 transition-transform duration-500">
                    <service.icon className="w-8 h-8 text-primary group-hover:animate-pulse" />
                  </div>
                </div>
              </div>

              {/* Content */}
              <h3 className="text-2xl font-bold mb-6 group-hover:text-primary transition-colors duration-300">
                {service.title}
              </h3>
              <ul className="space-y-4">
                {service.description.map((item, idx) => (
                  <li
                    key={idx}
                    className="text-muted-foreground flex items-start group/item"
                  >
                    <span
                      className={`mr-3 w-1.5 h-1.5 rounded-full mt-2.5 bg-gradient-to-r ${service.gradient} group-hover/item:scale-150 transition-transform duration-300`}
                    />
                    <span className="leading-relaxed group-hover/item:text-foreground transition-colors duration-300">
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
