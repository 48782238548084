import React from 'react';

interface LogoProps {
  className?: string;
}

export function Logo({ className = "" }: LogoProps) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      xmlSpace="preserve" 
      viewBox="0 0 425.7 221.2"
      className={`h-8 w-auto ${className}`}
    >
      <path 
        fill="#ffbf12" 
        d="M283.9 155.1c-3-3.2-7.3-5.2-12-5.2-4.8 0-9.1 2.1-12.1 5.3-.2.2-.4.5-.6.7-.8.9-1.7 1.8-2.6 2.7-2 2-4.2 3.9-6.5 5.6-10.4 7.9-23.2 12.5-37.3 12.5-14.1 0-26.9-4.6-37.3-12.5-2.3-1.7-4.4-3.6-6.5-5.6-.9-.9-1.8-1.8-2.6-2.8-.2-.2-.4-.5-.6-.7-3-3.3-7.3-5.4-12.1-5.4-4 0-7.6 1.4-10.5 3.8-.4.4-.7.8-1.1 1.2-2.6 3.2-3.5 6.3-3.5 9.5 0 5.3 2.9 10.8 6.3 15.4 3.4 4.5 7.3 8 8.9 9.3.4.4.7.5.7.5 4.9 3.8 10.1 7.1 15.6 9.9 12.8 6.5 27.4 10.1 42.7 10.1 15.3 0 29.8-3.7 42.6-10.1v5.3c0 9.1 7.3 16.4 16.4 16.4 9.1 0 16.4-7.3 16.4-16.4v-5.3h.1V166s.2-5-3.5-9.8l-.2-.2c-.1-.3-.4-.6-.7-.9z"
      />
      <path 
        fill="currentColor"
        d="M212.9 52.9c14.1 0 26.9 4.6 37.3 12.5 2.3 1.7 4.4 3.6 6.5 5.6.9.9 1.8 1.8 2.6 2.8.2.2.3.4.5.5 3 3.4 7.4 5.5 12.2 5.5 4.5 0 8.6-1.8 11.6-4.8l.6-.6c2.3-3 3.1-6 3.1-9 0-5.3-2.9-10.8-6.3-15.4-3.4-4.5-7.3-8-8.9-9.3l-.1-.1c-5.1-4-10.5-7.6-16.4-10.5-12.8-6.5-27.4-10.2-42.7-10.1-15.3 0-29.8 3.7-42.6 10.1-4.3 2.2-8.4 4.7-12.3 7.4 1.7 1.4 4.4 4 7.2 7.3 4.4 5.3 8.9 12.4 9 20.5v1.1c.5-.4 1-.8 1.5-1.1 10.2-7.7 23.1-12.3 37.2-12.4zM94.7 176.8c-17.1 0-32.5-6.9-43.8-18.1-11.2-11.3-18.1-26.6-18.1-43.8 0-17.1 6.9-32.5 18.1-43.8C62.2 59.8 77.6 53 94.7 52.9c14.1 0 26.9 4.6 37.3 12.5 2.3 1.7 4.4 3.6 6.5 5.6.9.9 1.8 1.8 2.6 2.8l.6.6c3 3.3 7.3 5.4 12.1 5.4 4.5 0 8.6-1.8 11.6-4.8.1-.1.2-.3.4-.4 2.4-3.1 3.3-6.1 3.3-9.2 0-5.3-2.9-10.8-6.3-15.4-3.4-4.5-7.3-8-8.9-9.3l-.1-.1c-5.1-4-10.5-7.6-16.4-10.5C124.6 23.6 110 19.9 94.7 20c-23.6 0-45.3 8.7-61.9 23V16.4C32.8 7.3 25.5 0 16.4 0 7.3 0 0 7.3 0 16.4v102c0 .9.1 1.7.2 2.5 1.5 23.7 11.8 45.2 27.5 60.9 17.1 17.1 40.9 27.8 67 27.7 15.3 0 29.8-3.7 42.6-10.1 4.3-2.2 8.4-4.7 12.3-7.5-1.7-1.5-4.3-4-7-7.2-4.4-5.3-8.9-12.4-9-20.5 0-.5 0-.9.1-1.4-.6.5-1.1.9-1.7 1.4-10.3 7.9-23.2 12.6-37.3 12.6z"
      />
      <path 
        fill="currentColor"
        d="M425.7 16.4c0-9.1-7.3-16.4-16.4-16.4-9.1 0-16.4 7.3-16.4 16.4v26.7c-16.6-14.3-38.3-23-61.9-23-15.3 0-29.8 3.7-42.6 10.1-4.3 2.2-8.4 4.6-12.3 7.4 1.7 1.4 4.4 4 7.2 7.4 4.4 5.3 8.9 12.4 9 20.5v1.1c.5-.4.9-.7 1.4-1.1C304.1 57.6 316.9 53 331 53c17.1 0 32.5 6.9 43.8 18.1 11.2 11.3 18.1 26.6 18.1 43.8 0 17.1-6.9 32.5-18.1 43.8-11.3 11.2-26.6 18.1-43.8 18.1-14.1 0-26.9-4.6-37.3-12.5-.1-.1-.2-.1-.3-.2v37.8c11.5 5 24.2 7.8 37.6 7.8 26.1 0 49.9-10.6 67-27.7s27.8-40.9 27.7-67V16.4z"
      />
      <path 
        fill="currentColor"
        d="M331.8 143.1c15.6 0 28.2-12.6 28.2-28.2s-12.6-28.2-28.2-28.2-28.2 12.6-28.2 28.2c-.1 15.6 12.6 28.2 28.2 28.2zM95 86.7c-15.6 0-28.2 12.6-28.2 28.2s12.6 28.2 28.2 28.2 28.2-12.6 28.2-28.2c.1-15.6-12.6-28.2-28.2-28.2z"
      />
    </svg>
  );
}
